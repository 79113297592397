<template>
   <!-- <a-modal class="widget-styles-color" :title="null" :footer="null" :closable="false" :bodyStyle="{padding:0}" :visible="data.show" > -->
      <a-popover trigger="click" v-model="show" :placement="placement">
        <div style="display:flex;" slot="content">
          <div style="display:flex; align-items:center">
            <div :style="{background:typeof color === 'string'? color : (color && color.hex), width:'10px'}" />
            <Material v-model="color"  class="custom-slider" style="height:auto;"/>
          </div>
          <div style="padding:30px;">

            <Slider v-model="color" style="max-width:290px;margin-top:5px;"/>
            <div role="group" class="vc-slider-swatches" style="margin-top:10px;">
              <div @click="setColor('#FFFFFF')"  aria-label="color:#FFFF00" role="button" class="vc-slider-swatch"><div class="vc-slider-swatch-picker" :class="isActive('#FFFFFF')? 'vc-slider-swatch-picker--active' : ''" style="background: #ffffff; box-shadow:0 2px 5px rgba(100,100,100,0.3)"></div></div>
              <div @click="setColor('#DDDDDD')" aria-label="color:#FFFF00" role="button" class="vc-slider-swatch"><div class="vc-slider-swatch-picker" :class="isActive('#DDDDDD')? 'vc-slider-swatch-picker--active' : ''" style="background: #dddddd; box-shadow:0 2px 5px rgba(100,100,100,0.3)"></div></div>
              <div @click="setColor('#AAAAAA')"  aria-label="color:#FFFF00" role="button" class="vc-slider-swatch"><div class="vc-slider-swatch-picker" :class="isActive('#AAAAAA')? 'vc-slider-swatch-picker--active' : ''" style="background: #aaaaaa; box-shadow:0 2px 5px rgba(100,100,100,0.3)"></div></div>
              <div @click="setColor('#666666')"  aria-label="color:#FFFF00" role="button" class="vc-slider-swatch"><div class="vc-slider-swatch-picker" :class="isActive('#666666')? 'vc-slider-swatch-picker--active' : ''" style="background: #666666; box-shadow:0 2px 5px rgba(100,100,100,0.3)"></div></div>
              <div @click="setColor('#000000')"  aria-label="color:#FFFF00" role="button" class="vc-slider-swatch"><div class="vc-slider-swatch-picker" :class="isActive('#000000')? 'vc-slider-swatch-picker--active' : ''" style="background: #000000; box-shadow:0 2px 5px rgba(100,100,100,0.3)"></div></div>
            </div>

            <a-divider>Recent Colors</a-divider>

            <!-- <Compact v-model="color" /> -->

            <div role="group" class="vc-slider-swatches" style="margin-top:10px;">
              <div v-for="(clr,clrI) in recentColors" :key="`recentColor${clr}${clrI}`" @click="setColor(clr)"  :aria-label="`color:${clr}`" role="button" class="vc-slider-swatch"><div class="vc-slider-swatch-picker" :class="isActive(clr)? 'vc-slider-swatch-picker--active' : ''" :style="`background:${clr} ; box-shadow:0 2px 5px rgba(100,100,100,0.3)`"></div></div>
            </div>

          </div>
        </div>
        <a-avatar :style="{background:typeof color === 'string'? color : (color && color.hex), border:'1px solid #eee', boxShadow:'1px 2px 5px rgba(200,200,200,0.5)'}" class="bordered-avatar" />
      </a-popover>

      <!-- </a-modal> -->
</template>

<script>
import {Slider, Material,Compact} from 'vue-color'
export default {
    components:{Slider,Material,Compact,},
    props:{
		value: {
			type:String,
		},
		placement: {
			type:String,
			default: 'top'
		}
	},
    data(){
        return {
            color:this.value,
            show:false,
            rcnt:['#FFFFFF','#DDDDDD','#000000']
        }
    },
    watch:{
      color(val){
        console.log('VALLLLL',val)
        this.$emit('input', this.color && this.color.hex || '#FFFFFF')
      },
      show(val){
        // if (val) this.color = {hex:this.value}
        if (val) this.color = this.value || {hex:'#ccc'}
      }
    },
    computed:{
      recentColors(){
        let clrs =  this.$store.state.email.cson.colors || []
        clrs = clrs.filter( (x,xI) => xI < 10)
        if (clrs.length <= 7) clrs = [...clrs,...this.rcnt]
        return clrs
      }
    },
    methods:{
      setColor(color){
        this.color = {hex:color}
      },
      isActive(clr){

        let color = this.color
        if (typeof color !== 'string') color = color && color.hex || '#FFFFFF'

        color = color.toLowerCase().trim()
        clr = clr.toLowerCase().trim()

        return clr === color
      },
      okColor(){
        let data = this.data
        if (data && typeof data.callback === 'function'){
          data.callback(this.color)
        }else {
          return this.$emit('ok', this.color)
        }

      }
    },
    created(){

    }
}
</script>

<style>

</style>
